.leaflet-container {
  width: 100%;
  height: 70vh;
}
.footer-rod {
  border-radius: 4px 0 0 0;
  height: 100%;
  width: 100%;
  font-weight: bolder;
  background-color: rgb(42, 42, 42);
  color: white;
  font-size: 10px;
  padding: 2px;
}
.footer-rod-container {
  position: absolute;
  width: 150px;
  background-color: transparent;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.media-icon {
  cursor: pointer;
}

.media-icon:hover {
  opacity: 0.5;
}

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #7795f8;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #829fff;
  border-radius: 4px;
}

.FormRow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #819efc;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.buttonPay {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 40px;
  margin: 40px 15px 0;
  background-color: #f6a4eb;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
}

.buttonPay:active {
  background-color: #d782d9;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08),
    inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}

.phone-detail-container {
  background: linear-gradient(90deg, #231f20 0%, #58595b 100%);
  color: white;
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 525px) {
  .phone-detail-container {
    justify-content: center;
    flex-direction: column;
  }
}

iframe.jobber-work-request {
  width: 100%;
  height: 100%;
  max-width: 760px;
  background: #ffffff;
  border-radius: 8px;
  border: 0;
  padding: 4px;
  box-sizing: border-box;
  visibility: hidden;
  overflow: hidden;
}
.jobber-inline-work-request {
  margin: 0 auto;
  max-width: 760px;
  box-sizing: border-box;
}
.jobber-dialog-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow-y: scroll;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: rgba(101, 120, 132, 0.8);
  -webkit-overflow-scrolling: touch;
}
@media print {
  .jobber-dialog-overlay {
    position: static;
    background-color: #ffffff;
  }
}
.jobber-dialog-box {
  width: 100%;
  min-height: 300px;
  max-width: 672px;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin: auto;
  margin-bottom: auto;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
}
@media print {
  .jobber-dialog-box {
    border: 1px solid #e1e1e1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.jobber-dialog-content {
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: #424e56;
  flex: auto;
}
.jobber-dialog-content p {
  margin-top: 0;
  margin-bottom: 16px;
}
.jobber-dialog-content > *:last-child {
  margin-bottom: 0;
}
.jobber-spinner {
  position: absolute;
  pointer-events: none;
  opacity: 0.6;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.jobber-spinner:after {
  position: absolute;
  z-index: 3;
  display: block;
  content: "";
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  margin: -18px 0 0 -18px;
  background-color: rgba(1, 27, 37, 0.3);
  border: 6px solid #011b25;
  border-top-color: #ffffff;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 2px 3px rgba(1, 27, 37, 0.3);
  box-shadow: 0px 0px 2px 3px rgba(1, 27, 37, 0.3);
  -webkit-animation-name: animation-spin;
  animation-name: animation-spin;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  will-change: transform;
}
@-webkit-keyframes animation-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes animation-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.jobber-button {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 36px;
  padding: 8px 12px;
  font-family: "Noto Sans", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  -webkit-transition: background-color 0.2s, opacity 0.2s;
  transition: background-color 0.2s, opacity 0.2s;
  vertical-align: middle;
}
.jobber-button--green {
  color: #ffffff;
  background-color: #7db00e;
}
.jobber-button--green:hover,
.jobber-button--green:focus {
  color: #ffffff;
  background-color: #5b810a;
}
.jobber-button--green:active {
  color: #ffffff;
  background-color: #4b6908;
}
.jobber-button:focus {
  -webkit-box-shadow: 0 0 4px 1px #e7d557;
  box-shadow: 0 0 4px 1px #e7d557;
  outline: none;
}
.jobber-hidden {
  display: none !important;
}
.jobber-button--disabled {
  background-color: #e1e1e1 !important;
  pointer-events: none !important;
}
.jobber-flash {
  margin: 0 auto;
  max-width: 760px;
  box-sizing: border-box;
  position: relative;
  margin: 16px;
  padding: 16px;
  font-family: helvetica, arial, sans-serif;
  color: #1a1a1a;
  text-align: center;
  border-top: 1px solid #cbcbcb;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f4f4f4;
}
.jobber-flash--error {
  color: #541e12;
  background-color: #f9bcad;
  border-color: #ef5733;
}
